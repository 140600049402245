<template>
    <div>

        <sui-form>
            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name2'] }}</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['name2']"
                    v-model="countryPreset.name"
                />
            </sui-form-field>

            <sui-form-field width="eight" class="backoffice-file-upload">
                <label>{{ $store.state.adminLanguage['flag'] }}</label>
                <input type="file" :ref="'flag'" hidden v-on:change="uploadFlag($event)" />
                <img class="backoffice-flag-upload__button" v-on:click="pickFlag()" :src="countryPreset.flag" />
            </sui-form-field>

            <h3 is="sui-header">{{ $store.state.adminLanguage['cities'] }}</h3>

            <sui-segment padded color="green" raised v-for="(city, index) in cityPresets">

                <sui-form-fields>
                    <sui-form-field class="backoffice-file-upload">
                        <input type="file" :ref="'picture' + city.id" hidden v-on:change="uploadPicture($event, city)" />
                        <img class="backoffice-file-upload__button" v-on:click="pickPicture(city.id)" :src="city.picture" />
                    </sui-form-field>
                    <sui-form-field width="nine">
                        <label>{{ $store.state.adminLanguage['name2'] }}</label>
                        <input
                            type="text"
                            :placeholder="$store.state.adminLanguage['name2']"
                            v-model="city.name"
                        />
                    </sui-form-field>
                    <sui-form-field width="four">
                        <label>{{ $store.state.adminLanguage['economy_level'] }}</label>
                        <input
                            type="text"
                            v-model="city.economy"
                        />
                    </sui-form-field>
                </sui-form-fields>

            </sui-segment>

            <sui-message error visible v-if="errors">
                <sui-message-list>
                    <sui-message-item v-for="error in errors">
                        {{ error[0] }}
                    </sui-message-item>
                </sui-message-list>
            </sui-message>

            <sui-form-field>
                <br>
                <sui-button basic positive v-on:click="update()">{{ $store.state.adminLanguage['update'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

    export default {
        name: 'GameCountriesEdit',
        data () {
            return {
                countryPresetID: null,
                countryPreset: {},
                cityPresets: [],

                uploadingPicture: null,
                uploadingFlag: null,

                errors: null,
                loading: {
                    update: false,
                },
            }
        },

        methods: {
            update() {
                this.loading.update = true;
                let data = {
                    countryPreset: this.countryPreset,
                    cityPresets: this.cityPresets,
                };
                let that = this;
                this.axios.post('/api/backoffice/country_presets/update', data)
                    .then((response) => {
                        that.$router.push({name: 'GameCountriesList'})
                    })
                    .catch(function (error) {
                        that.errors = error.response.data.errors;
                        that.loading.update = false;
                    })
            },

            getCountryPreset() {
                let that = this;
                this.axios.get('/api/backoffice/country_presets/get', {
                    params: {
                        id: this.countryPresetID,
                    }
                }).then((response) => {
                    that.countryPreset = response.data.countryPreset;
                    that.cityPresets = response.data.cityPresets;
                    //this.$parent.additionalSection = {name: that.countryPreset.name};
                })
            },

            pickPicture(cityID) {
                this.$refs['picture' + cityID][0].click()
            },

            uploadPicture(event, city) {
                this.uploadingPicture = event.target.files[0];
                let data = new FormData();
                data.append('picture', this.uploadingPicture);

                let that = this;
                this.axios.post('/api/backoffice/upload_picture', data)
                .then((response) => {
                    city.picture = response.data;
                })
            },

            pickFlag() {
                this.$refs['flag'].click()
            },

            uploadFlag(event) {
                this.uploadingFlag = event.target.files[0];
                let data = new FormData();
                data.append('flag', this.uploadingFlag);

                let that = this;
                this.axios.post('/api/backoffice/upload_flag', data)
                    .then((response) => {
                        this.countryPreset.flag = response.data;
                    })
            },

        },
        mounted() {
            this.countryPresetID = this.$route.params.countryId;
            this.getCountryPreset();
        }
    }
</script>


