<template>
    <div>
        <sui-form>
            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name2'] }}</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['name2']"
                    v-model="logo.name"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>SVG</label>
                <textarea
                    v-model="logo.svg"
                ></textarea>
            </sui-form-field>

            <sui-form-field>
                <br>
                <sui-button basic positive v-on:click="add()">{{ $store.state.adminLanguage['add'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

    export default {
        name: 'GameLogosAdd',
        data () {
            return {
                logo: {},
            }
        },

        methods: {
            add() {
                let data = {
                    logo: this.logo,
                };
                let that = this;
                this.axios.post('/api/backoffice/logos/add', data).then((response) => {
                    that.$parent.activeSection = 'GameLogosList';
                    that.$router.push({name: 'GameLogosList'})
                })
            },
        },
    }
</script>


