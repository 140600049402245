<template>
    <div>

        <div class="ui form">

            <h2 is="sui-header">{{ $store.state.adminLanguage['main_info'] }}</h2>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name2'] }}</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['name2']"
                    v-model="game.title"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['game_preset'] }}</label>
                <sui-dropdown
                    :options="variablesPresetsOptions"
                    :placeholder="$store.state.adminLanguage['choose_preset']"
                    search
                    selection
                    v-model="game.preset"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['logo'] }}</label>
                <sui-dropdown
                    :options="logosOptions"
                    search
                    selection
                    v-model="game.logo"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['partners'] }}</label>
                <sui-dropdown
                    :options="partnersOptions"
                    search
                    selection
                    v-model="game.partner"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['game_language'] }}</label>
                <sui-dropdown
                    :options="languagesOptions"
                    :placeholder="$store.state.adminLanguage['choose_language']"
                    search
                    selection
                    v-model="game.language"
                />
            </sui-form-field>

            <h3 is="sui-header" v-if="countriesOptionsAvailable.length">{{ $store.state.adminLanguage['countries'] }}</h3>

            <sui-form-field width="eight">
                <div class="backoffice-countries">
                    <div class="backoffice-countries__item" v-for="item in countriesOptionsAvailable">
                        <sui-checkbox
                            :label="item.text"
                            :value="item.value"
                            v-model="teams"
                        />
                            <!-- :disabled="teams.length >= 9 && teams.indexOf(item.value) === -1" -->
                    </div>
                </div>
            </sui-form-field>

            <!--
            <h3 is="sui-header">Команды</h3>
            <sui-segment padded color="green" raised v-for="team in teams">
                <sui-form-field width="eight">
                    <label>Страна</label>
                    <sui-dropdown
                        :options="countriesOptions"
                        placeholder="Выберите страну"
                        search
                        selection
                        v-model="team.countryPreset"
                    />
                </sui-form-field>
            </sui-segment>
            -->

            <div class="backoffice-button-with-text">
                <!-- <sui-button color="green" :disabled="!canAddTeam" floated="left" basic v-on:click.prevent="addTeam()"><i class='add icon'></i>Добавить команду</sui-button> -->
                <div class="backoffice-button-with-text__text">
                    <p>{{ $store.state.adminLanguage['teams_limit'] }}</p>
                </div>
            </div>

            <h2 is="sui-header">{{ $store.state.adminLanguage['mods'] }}</h2>
            <sui-form-field width="eight">
                <div class="backoffice-countries">
                    <div class="backoffice-countries__item">
                        <sui-checkbox
                            :label="$store.state.adminLanguage['ufo']"
                            :value="'ufo'"
                            v-model="mods"
                        />
                    </div>
                    <div class="backoffice-countries__item">
                        <sui-checkbox
                            :label="$store.state.adminLanguage['negotiation']"
                            :value="'negotiation'"
                            v-model="mods"
                        />
                    </div>
                    <div class="backoffice-countries__item">
                        <sui-checkbox
                            :label="'Логотип МГПУ'"
                            :value="'mgpu'"
                            v-model="mods"
                        />
                    </div>
                    <div class="backoffice-countries__item">
                        <sui-checkbox
                            :label="$store.state.adminLanguage['bliz_game']"
                            :value="'bliz'"
                            v-model="mods"
                        />
                    </div>
                </div>
            </sui-form-field>

            <sui-message error visible v-if="errors">
                <sui-message-list>
                    <sui-message-item v-for="error in errors">
                        {{ error[0] }}
                    </sui-message-item>
                </sui-message-list>
            </sui-message>

            <sui-form-field>
                <br>
                <sui-button color="green" v-bind="{loading: loading.create}" v-on:click="addGame()">{{ $store.state.adminLanguage['add_game'] }}</sui-button>
            </sui-form-field>
        </div>

    </div>
</template>

<script>

    export default {
        name: 'HostGamesAdd',
        data () {
            return {
                game: {},
                teams: [],
                mods: [],

                errors: null,
                loading: {
                    addGame: false,
                },

                user: null,
                languagesOptions: [],
                countriesOptions: [],
                variablesPresetsOptions: [],
            }
        },

        computed: {

            countriesOptionsAvailable: function () {
                let countriesOptionsAvailable = [];
                if (this.game.language) {
                    this.countriesOptions.forEach(function (countryPreset) {
                        if (countryPreset.languages.indexOf(this.game.language) !== -1) {
                            countryPreset.text = countryPreset.translations[this.game.language].value;
                            countriesOptionsAvailable.push(countryPreset);
                        }
                    }, this);

                    return countriesOptionsAvailable;
                } else {

                    return [];
                }
            },

            /*
            canAddTeam: function() {

                return (this.teams.length < 9);
            },
            */
        },

        methods: {

            addGame() {
                this.loading.addGame = true;
                this.errors = null;
                let data = {
                    game: this.game,
                    teams: this.teams,
                    mods: this.mods,
                };
                let that = this;
                this.axios.post('/api/backoffice/host/games/add', data)
                    .then((response) => {
                        that.$router.push({name: 'HostGamesList'})
                    })
                    .catch(function (error) {
                        that.errors = error.response.data.errors;
                        that.loading.addGame = false;
                    })
            },

            /*
            addTeam() {
                let team = {

                };
                this.teams.push(team)
            },
            */

            getLanguagesOptions() {
                this.axios.get('/api/backoffice/get_languages_options')
                    .then((response) => {
                        this.languagesOptions = response.data;
                        this.languagesOptions.forEach(element => {
                            if (element.key === this.user.locale) {
                                // this.game.language = element.value;
                            }
                        });
                    });
            },

            getCountriesOptions() {
                this.axios.get('/api/backoffice/get_countries_options')
                    .then((response) => {
                        this.countriesOptions = response.data;
                    });
            },

            getLogosOptions() {
                this.axios.get('/api/backoffice/get_logos_options')
                    .then((response) => {
                        this.logosOptions = response.data;
                    });
            },

            getPartnersOptions() {
                this.axios.get('/api/backoffice/get_partners_options')
                    .then((response) => {
                        this.partnersOptions = response.data;
                    });
            },

            getVariablesPresetsOptions() {
                this.axios.get('/api/backoffice/get_variables_presets_options')
                    .then((response) => {
                        this.variablesPresetsOptions = response.data;
                    });
            },

        },
        mounted() {
            this.user = this.$store.getters.getUser;

            this.getLanguagesOptions();
            this.getCountriesOptions();
            this.getLogosOptions();
            this.getPartnersOptions();
            this.getVariablesPresetsOptions();

            /*
            for (let i = 0; i < 3; i++) {
                this.addTeam();
            }
            */

        }
    }
</script>


