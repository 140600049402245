<template>
    <div>

        <div class="ui top attached tabular menu">

            <router-link
                :to="{name: 'HostGamesList'}"
                is="sui-menu-item"
                :active="activeSection === 'HostGamesList'"
                :content="$store.state.adminLanguage['games_list']"
                v-on:click.native="setActiveSection('HostGamesList')"
            />

            <div class="right menu">
                <router-link
                    v-if="$route.name === 'HostGamesList'"
                    :to="{name: 'HostGamesAdd'}"
                    is="sui-menu-item"
                    :active="activeSection === 'HostGamesAdd'"
                    v-on:click.native="setActiveSection('HostGamesAdd')"
                >
                    <i class='add icon'></i>{{ $store.state.adminLanguage['add_game'] }}
                </router-link>
            </div>
        </div>

        <div class="ui bottom attached segment padded">
            <router-view></router-view>
        </div>

    </div>
</template>

<script>
    import SectionMixin from '../mixins/sectionMixin';

    export default {
        name: 'HostGames',
        mixins: [SectionMixin],
        data () {
            return {

            }
        },
        methods: {

        },
        mounted() {
            this.activeSection = this.$router.history.current.name;
        }
    }
</script>


