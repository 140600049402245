<template>
    <div>

        <sui-form>
            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name2'] }}</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['name2']"
                    v-model="logo.name"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>SVG</label>
                <textarea
                    v-model="logo.svg"
                ></textarea>
            </sui-form-field>

            <sui-message error visible v-if="errors">
                <sui-message-list>
                    <sui-message-item v-for="error in errors">
                        {{ error[0] }}
                    </sui-message-item>
                </sui-message-list>
            </sui-message>

            <sui-form-field>
                <br>
                <sui-button basic positive v-on:click="update()">{{ $store.state.adminLanguage['update'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

    export default {
        name: 'GameCountriesEdit',
        data () {
            return {
                logoID: null,
                logo: {},

                errors: null,
                loading: {
                    update: false,
                },
            }
        },

        methods: {
            update() {
                this.loading.update = true;
                let data = {
                    logo: this.logo,
                };
                let that = this;
                this.axios.post('/api/backoffice/logos/update', data)
                    .then((response) => {
                        that.$router.push({name: 'GameLogosList'})
                    })
                    .catch(function (error) {
                        that.errors = error.response.data.errors;
                        that.loading.update = false;
                    })
            },

            getLogo() {
                let that = this;
                this.axios.get('/api/backoffice/logos/get', {
                    params: {
                        id: this.logoID,
                    }
                }).then((response) => {
                    that.logo = response.data.logo;
                })
            },
        },
        mounted() {
            this.logoID = this.$route.params.logoId;
            this.getLogo();
        }
    }
</script>


